






import { Component, Vue, Watch } from 'vue-property-decorator';
import FlowViewer from '@/views/home/flow2/flow-viewer.vue';
import { readRuntimeTask } from '@/api/flow-design/FlowApi';

@Component({
  name: 'UserHomeFlowArchive',
  components: {
    FlowViewer
  }
})
export default class UserHomeFlowArchive extends Vue {
  operation: string = 'archive';
  isShow: boolean = false;

  get _taskDataId() {
    return this.$route.query.id;
  }

  private created(): void {
    this.readTask();
    this.$nextTick(() => {
      this.isShow = true;
    });
  }

  readTask() {
    readRuntimeTask(this.$route.query.id as string).then((res: Record<any, any>) => {
      if (res.code != '1') {
        this.$message.error(res.message);
      }
    });
  }

  @Watch('_taskDataId')
  taskDataIdWatcher(val) {
    this.isShow = false;
    this.$nextTick(() => {
      this.isShow = true;
      this.readTask();
    });
  }
}
